<template>
  <div>
    <p v-if="loading">
      <b-spinner label="Loading..." />
    </p>
    <div v-if="description && !loading">
      <p v-html="description" />
    </div>
  </div>
</template>

<script>
import DescriptionApis from '@/services/apis/description'

const description = new DescriptionApis()
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      description: null,
      loading: false,
    }
  },
  watch: {
    '$store.state.appConfig.layout.isRTL': {
      handler() {
        this.getDescriptions()
      },
    },
  },
  async created() {
    await this.getDescriptions()
  },
  methods: {
    async getDescriptions() {
      this.loading = true
      const { name } = this.$props
      await description.get({ name }).then(res => {
        this.description = res.data.results.value
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>
/* Add your styles here */
</style>

<template>
  <b-overlay
    v-if="exchangeDeposit"
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-container class="mt-5">
      <b-row>
        <b-col>
          <b-alert
            variant="info"
            class="text-center p-1"
            show
          >
            <h2>
              {{ $t('deposit') }}
            </h2>
            <b-button
              variant="warning"
              @click="showTutorialVideo = true"
            >
              {{ $t('tutorial-video') }}
            </b-button>
            <p>
              {{ $t('deposit-instructions') }}
            </p>
          </b-alert>
        </b-col>
      </b-row>
      <b-card class="mt-1 mb-1">
        <b-row class="steps">
          <b-col
            v-for="(step, index) in steps"
            :key="index"
            xs="12"
            md="4"
            class="text-center"
          >
            <div class="step-number">
              {{ index + 1 }}
            </div>
            <p>{{ $t(`step-${index + 1}`) }}</p>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="mt-1 mb-1 p-0">
        <b-table
          striped
          responsive
          hover
          :items="exchanges"
          :fields="fields"
          class="text-center"
        >
          <template #cell(select)="data">
            <b-button
              :disabled="selectedExchanget.name === data.item.name"
              :variant="selectedExchanget.name === data.item.name ? 'success' : 'primary'"
              style="min-width: 100px;"
              @click="setExchangeDetails(data.item)"
            >
              <span
                v-if="selectedExchanget.name !== data.item.name"
                style="white-space: nowrap;"
              >{{ $t('select') }}</span>
              <span
                v-else
                style="white-space: nowrap;"
              >✔ {{ $t('selected') }}</span>
            </b-button>
          </template>
        </b-table>
        <p
          class="text-center"
          style="font-weight: bold;"
        >
          {{ $t('wallet-instructions') }}
        </p>
        <GetDescription name="forex-exchange-deposit" />

      &nbsp;
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('platform-account-id')"
              label-for="account-number"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('platform-account-id')"
                vid="account-number"
                rules="required"
              >
                <v-select
                  id="account-number"
                  v-model="account"
                  :options="accountsItems"
                  :clearable="false"
                  label="account"
                  :dir="$store.state.appConfig.layout.isRTL?'rtl':'ltr'"
                  @input="getExchangeData(account.account, platform.value)"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('platform')"
              label-for="platform"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('platform')"
                vid="platform"
                rules="required"
              >
                <v-select
                  id="platform"
                  v-model="platform"
                  :options="platforms"
                  label="name"
                  :dir="$store.state.appConfig.layout.isRTL?'rtl':'ltr'"
                  :clearable="false"
                  disabled
                  @input="getExchangeData(account.account, platform.value)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="w-100 w-lg-50 mx-auto">
          <b-col class="text-center d-lg-flex justify-content-center align-items-center">
            <h5 class="flex-shrink-0">
              {{ $t('wallet-address') }}
            </h5>
          &nbsp;
            <b-input-group>
              <b-form-input
                readonly
                :value="selectedExchanget.address_wallet || $t('select-exchange')"
                class="text-center"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="copyToClipboard(selectedExchanget.address_wallet)"
                >
                  {{ $t('copy') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 w-100 w-lg-50 mx-auto">
          <b-col
            class="text-center text-center d-lg-flex justify-content-center align-items-center"
          >
            <h5 class="flex-shrink-0">
              {{ $t('account-number') }}
            </h5>
          &nbsp;
            <b-input-group>
              <b-form-input
                readonly
                :value="wallet_number || $t('select-exchange')"
                class="text-center"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="copyToClipboard(wallet_number)"
                >
                  {{ $t('copy') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col
            cols="12"
            class="text-center mt-1"
          >
            <b-button
              v-if="selectedExchanget.address_wallet && wallet_number"
              variant="success"
              :href="selectedExchanget.url"
              target="_blank"
            >
              {{ $t('go-to') }} {{ selectedExchanget.name }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-alert
        variant="danger"
        show
        class="text-center mt-1 p-2"
      >
        {{ $t('support-contact') }}
      </b-alert>
    </b-container>
    <b-modal
      id="tutorial-modal"
      v-model="showTutorialVideo"
      :title="$t('tutorial-video')"
      hide-footer
      size="lg"
    >
      <video
        controls
        autoplay
        style="width: 100%"
      >
        <source
          src="/exchange0pay-tutrial.mp4"
          type="video/mp4"
        >
        Your browser does not support the video tag.
      </video>
    </b-modal>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import MetatraderApis from '../../service/apis'
import GetDescription from './components/getDescription.vue'

const metatrader = new MetatraderApis()
export default {
  components: { vSelect, GetDescription },
  data() {
    return {
      show: true,
      platforms: [
        { title: 'metatrader 4', value: 'mt4' },
      ],
      showTutorialVideo: true,
      account: {},
      wallet_number: '',
      selectedExchanget: {},
      accountsItems: [],
      platform: '',
      steps: [
        'step-1',
        'step-2',
        'step-3',
      ],
      fields: [
        { key: 'name', label: this.$t('online-exchange') },
        { key: 'min_deposit', label: this.$t('min-deposit'), formatter: value => `${value} دلار` },
        { key: 'select', label: this.$t('select') },
      ],
      exchanges: [],
    }
  },
  computed: {
    exchangeDeposit() {
      return process.env.VUE_APP_IS_ENABLED_FOREX_EXCHANGE_DEPOSIT === 'true'
    },
  },
  mounted() {
    this.getExchangeGateway()
  },
  methods: {
    async getExchangeGateway() {
      this.show = true
      await Promise.all([
        metatrader.getPlatforms().then(res => {
          this.platforms = res.data.results
        }),
        await metatrader.getAccounts().then(res => {
          this.accountsItems = res.data.results
          // eslint-disable-next-line prefer-destructuring
          this.account = this.$route.params.id ? res.data.results.find(acc => acc.account === parseInt(this.$route.params.id, 10)) : res.data.results[0]
          this.platform = this.platforms.find(plat => plat.value === res.data.results[0].platform)
          this.getExchangeData(this.account.account, this.platform.value)
        }),
      ]).catch(err => {
        console.log(err)
      }).finally(() => {
        this.show = false
      })
    },
    async getExchangeData(account, platform) {
      this.show = true
      await metatrader.getUserExchangeGateway(account, platform).then(res => {
        this.exchanges = res.data.results.exchanges
        this.wallet_number = res.data.results.tracking_code
        return res
      }).finally(() => {
        this.show = false
      })
    },
    setExchangeDetails(exchange) {
      this.selectedExchanget = exchange
    },
    copyToClipboard(value) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(value).then(() => {
          this.$bvToast.toast(this.$t('copy-success'), {
            title: this.$t('success'),
            variant: 'success',
            solid: true,
          })
        }).catch(err => {
          console.error('Failed to copy: ', err)
        })
      } else {
        const textArea = document.createElement('textarea')
        textArea.value = value
        document.body.appendChild(textArea)
        textArea.select()
        try {
          document.execCommand('copy')
          this.$bvToast.toast(this.$t('copy-success'), {
            title: this.$t('success'),
            variant: 'success',
            solid: true,
          })
        } catch (err) {
          console.error('Failed to copy: ', err)
        }
        document.body.removeChild(textArea)
      }
    },
  },
}
</script>

<style scoped>
.steps .step-number {
    background-color: #007bff;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    font-size: 18px;
}
</style>
